import { useEffect, useState } from "react";
import { ConfigProvider, Tabs } from "antd";
import P0Navbar from "../../Home/P0Navbar";
import PGRP1Header from "./PGRP1Header";
import P6Footers from "../../Home/P6Footers";
import axios from "axios";
import PGRP2Replist from "./PGRP2Replist";
import { pdfjs } from "react-pdf";
import PGRPLoading from "./PGRPLoading";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const apiMeetingReport = `${process.env.REACT_APP_API_URL}/noauth/clients/meeting/data`; // Table meetingrep
const apiMeetingYear = `${process.env.REACT_APP_API_URL}/noauth/clients/meeting/year`; // Table meetint.year

const PGReport = () => {
  const [dataMeetRepo, setDataMeetRepo] = useState([]);
  const [dataMeetYear, setDataMeetYear] = useState([]);

  const fetchDataMeetRepo = async () => {
    await axios.get(apiMeetingReport).then((res) => {
      setDataMeetRepo(res.data);
    });
  };

  const fetchDataMeetYear = async () => {
    await axios.get(apiMeetingYear).then((res) => {
      setDataMeetYear(res.data);
    });
  };

  useEffect(() => {
    fetchDataMeetRepo();
    fetchDataMeetYear();
  }, []);

  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PGRP1Header />
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                cardGutter: "50",
                itemSelectedColor: "#9171ee",
                itemHoverColor: "#fba504",
                cardBg: "null",
              },
            },
          }}
        >
          <Tabs
            type="card"
            size="large"
            animated={true}
            centered={true}
            tabPosition={"top"}
            items={dataMeetYear.map((item, i) => {
              const id = String(i + 1);
              const filteredData = dataMeetRepo.filter(
                (repo) => repo.repYear === item.year
              );
              return {
                label: `ประจำปี ${item.year}`,
                key: id,
                children: (
                  <>
                    {item.quantity ? (
                      <PGRP2Replist
                        SendYear={item.year}
                        SendData={filteredData}
                        SendId={item._id}
                      />
                    ) : null}
                  </>
                ),
              };
            })}
          />
        </ConfigProvider>
      </div>
      <P6Footers />
    </div>
  );
};
export default PGReport;
