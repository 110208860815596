const PMT2Service = ({ Titled }) => {
  const detail = "เพิ่มเติม";
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div
            className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="mt-5">{Titled}</h2>
          </div>
          <div className="row g-4">
            <div className="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-video fa-2x"></i>
                </div>
                <h5 className="mb-3">E-Meeting</h5>
                <p>
                  จัดทำระเบียบวาระการประชุมภายในมหาวิทยาลัยเชียงใหม่ อาทิ
                  การประชุมสภามหาวิทยาลัย การประชุมคณะกรรมการบริหารมหาวิทยาลัย
                  การประชุมคณะกรรมการบริหารงานบุคคลมหาวิทยาลัย เป็นต้น
                </p>
                <a
                  className="btn px-3 mt-auto mx-auto"
                  href="https://emeeting.cmu.ac.th/"
                  rel="noopener"
                  target="_blank"
                >
                  {detail}
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.2s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i class="fa fa-calendar fa-2x"></i>
                </div>
                <h5 className="mb-3">กำหนดการประชุม กบม.</h5>
                <p>
                  กำหนดการประชุมคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่ ประจำปี
                </p>
                <a
                  className="btn px-3 mt-auto mx-auto"
                  href={`${process.env.PUBLIC_URL}/Assets/PDF/กำหนดการประชุม_กบม_67.pdf`}
                  target="_blank"
                  rel="noopener"
                >
                  {detail}
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-search fa-2x"></i>
                </div>
                <h5 className="mb-3">สืบค้นรายงานการประชุมย้อนหลัง</h5>
                <p>
                  ไฟล์ข้อมูลรายงานการประชุมคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่ย้อนหลัง
                </p>
                <a
                  className="btn px-3 mt-auto mx-auto"
                  href="/meeting/report"
                  rel="noopener"
                >
                  {detail}
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.4s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-users fa-2x"></i>
                </div>
                <h5 className="mb-3">
                  รายชื่อคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่ (กบม.)
                </h5>
                <p>
                  รายชื่อองค์ประชุมคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่ (กบม.)
                </p>
                <a
                  className="btn px-3 mt-auto mx-auto"
                  href="/meeting/board"
                  rel="noopener"
                >
                  {detail}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PMT2Service;
