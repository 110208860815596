import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Image, Tooltip, Typography } from "antd";
import PHLoading from "./PHLoading";

const { Text } = Typography;

const apiURLNews = `${process.env.REACT_APP_API_URL}/noauth/clients/news/data`;

const P3Publicrt = (props) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [dataNews, setDataNews] = useState([]);
  const [page, setPage] = useState(0);
  const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    await axios.get(apiURLNews).then((res) => {
      setDataNews(res.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const newsShow = async (id) => {
    history.push(`/newslist/id?id=${id}`);
  };

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container px-lg-5 my-5">
          <div
            className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="mt-2">ประชาสัมพันธ์ทั่วไป</h2>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-12 text-center">
              <ul className="list-inline mb-5" id="portfolio-flters">
                <li className="btn px-3 pe-4 active" data-filter="*">
                  ทั้งหมด
                </li>

                <li className="btn px-3 pe-4">ข่าวสารทั่วไป</li>
                {/* <li className="btn px-3 pe-4" data-filter=".first">
                  ร่วมงานกับเรา
                </li> */}
                {/* <li className="btn px-3 pe-4" data-filter=".second">
                  แบบฟอร์ม
                </li> */}
              </ul>
            </div>
          </div>
          <div className="row g-4 portfolio-container">
            {loading ? (
              <PHLoading />
            ) : (
              <>
                {dataNews ? (
                  // eslint-disable-next-line
                  dataNews.map((item, index) => {
                    if (index < 8) {
                      return (
                        <>
                          <div
                            className="col-lg-4 col-md-6 portfolio-item first wow zoomIn"
                            data-wow-delay="0.1s"
                          >
                            <div className="position-relative publicrt-card-news">
                              {item.imageId === "" ? (
                                <Image
                                  className="img-fluid w-100"
                                  src={`${process.env.PUBLIC_URL}/Assets/img/logo-cropped.png`}
                                />
                              ) : (
                                <Image
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    float: "left",
                                  }}
                                  src={item.imageId}
                                />
                              )}

                              <div className="portfolio-overlay">
                                <Tooltip
                                  placement="top"
                                  title={"ดูรายละเอียดเพิ่มเติม"}
                                >
                                  <a
                                    className="btn btn-light"
                                    role="button"
                                    onClick={() => {
                                      newsShow(item._id.toString());
                                    }}
                                  >
                                    <i className="fa fa-plus fa-2x"></i>
                                  </a>
                                </Tooltip>

                                <div className="mt-auto">
                                  <a
                                    href="#"
                                    className="h5 d-block mb-2"
                                    style={{
                                      textShadow: "2px 2px 2px #000",
                                    }}
                                    onClick={() => {
                                      newsShow(item._id.toString());
                                    }}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={item.newsTitle}
                                    >
                                      <small className="titled">
                                        <EllipsisMiddle suffixCount={10}>
                                          {item.newsTitle}
                                        </EllipsisMiddle>
                                      </small>
                                    </Tooltip>
                                  </a>

                                  <Tooltip
                                    placement="bottomLeft"
                                    title={item.newsType}
                                  >
                                    <small className="type-title">
                                      <i className="fa fa-folder me-2"></i>
                                      <text className="portfolio-item-name">
                                        {item.newsType}
                                      </text>
                                    </small>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })
                ) : (
                  <>ไม่พบข้อมูล</>
                )}
              </>
            )}
          </div>
        </div>
        <div
          class="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-lg-6 col-md-6 col-12 wow fadeInUp">
            <a
              href="#"
              style={{ width: "100%", fontWeight: "bold" }}
              className="btn btn-personnel py-sm-3 px-sm-5 rounded-pill mt-5 animated fadeInUp"
            >
              ดูข่าวเพิ่มเติมทั้งหมด {dataNews.length} ข่าว
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const EllipsisMiddle = ({ suffixCount, children }) => {
  const start = children.slice(0, children.length - suffixCount).trim();
  const suffix = children.slice(-suffixCount).trim();
  return (
    <Text
      style={{
        color: "#fff",
        maxWidth: "100%",
      }}
      ellipsis={{
        suffix,
      }}
    >
      {start}
    </Text>
  );
};

export default P3Publicrt;
