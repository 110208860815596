import "./index.css";

const POCLoading = () => {
  return (
    <>
      <div id="box-container">
        <div className="boxes box1"></div>
        <div className="boxes box2"></div>
        <div className="boxes box3"></div>
      </div>
      <div className="text">
        <div className="loading">
          <b>Loading</b>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default POCLoading;
