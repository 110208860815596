import { useEffect } from "react";

const P6Footers = () => {
  useEffect(() => {
    if (window.FB) {
      window.FB.init({
        appId: "https://www.facebook.com/CMUgeneral",
        status: true,
        xfbml: true,
        version: "v2.7", // or v2.6, v2.5, v2.4, v2.3
      });
    }
  }, []);

  return (
    <>
      <div
        className="container-fluid bg-primary text-light footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5 px-lg-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-4">
              <h5 className="text-white mb-4">ติดต่อกองบริหารงานกลาง</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>
                อาคารสำนักงานมหาวิทยาลัยเชียงใหม่ มหาวิทยาลัยเชียงใหม่ 239
                ถนนห้วยแก้ว ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>+66 5394 3011 – 14
              </p>
              <p>
                <i className="fa fa-fax me-3"></i>+66 5321 7143
              </p>
              <p>
                <i className="fa fa-fax me-3"></i>+66 53943002
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i>saraban@cmu.ac.th
              </p>

              <div className="d-flex pt-2">
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/CMUgeneral"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <h5 className="text-white mb-4">
                บริการสำคัญของมหาวิทยาลัยเชียงใหม่
              </h5>
              <a
                className="btn btn-link"
                href="https://www.cmu.ac.th/Content/University/CMUPhoneBook.pdf"
                target="_blank"
              >
                สมุดโทรศัพท์มหาวิทยาลัยเชียงใหม่
              </a>
              <a
                className="btn btn-link"
                href="https://www.cmu.ac.th/Content/University/BrochureCMU-Map2017.pdf"
                target="_blank"
              >
                แผนที่มหาวิทยาลัยเชียงใหม่
              </a>
              <a
                className="btn btn-link"
                href="https://portal.cmu.ac.th"
                target="_blank"
              >
                CMU MAIL
              </a>
              <a
                className="btn btn-link"
                href="https://mis.cmu.ac.th/cmumis"
                target="_blank"
              >
                CMU MIS
              </a>
              <a
                className="btn btn-link"
                href="https://cmubackoffice.mis.cmu.ac.th"
                target="_blank"
              >
                สำหรับเจ้าหน้าที่
              </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <h5 className="text-white mb-4">ช่องทางข่าวสาร</h5>
              <div
                class="fb-page"
                data-tabs="timeline,events,messages"
                data-href="https://www.facebook.com/CMUgeneral"
                max-width="100%"
                data-height="280px"
                style={{
                  height: "290px",
                  border: "6px solid #fff",
                  borderRadius: "5px",
                }}
                data-hide-cover="false"
                data-show-facepile="false"
              >
                <blockquote
                  cite="https://www.facebook.com/CMUgeneral"
                  class="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/CMUgeneral">
                    กองบริหารงานกลาง สำนักงานมหาวิทยาลัย มหาวิทยาลัยเชียงใหม่
                  </a>
                </blockquote>
              </div>
            </div>

            <div className="col-md-12 col-lg-12">
              <h5 className="text-white mb-4">แผนที่กองบริหารงานกลาง</h5>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.859734802923!2d98.95489959999999!3d18.8044033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3a651aaa1413%3A0xe3b9f47c9dacf5c7!2z4LiB4Lit4LiH4LiB4Lil4Liy4LiHIOC4quC4s-C4meC4seC4geC4h-C4suC4meC4oeC4q-C4suC4p-C4tOC4l-C4ouC4suC4peC4seC4oiDguKHguKvguLLguKfguLTguJfguKLguLLguKXguLHguKLguYDguIrguLXguKLguIfguYPguKvguKHguYg!5e0!3m2!1sth!2sth!4v1689178644430!5m2!1sth!2sth"
                width="100%"
                height="250"
                style={{
                  border: "10px solid #fff",
                  borderRadius: "5px",
                  padding: "6px",
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="container px-lg-5">
          <div className="copyright">
            <div className="row">
              <div className="col-md-8 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a
                  className="border-bottom"
                  href="https://www.cmu.ac.th/"
                  target="_blank"
                >
                  Chiang Mai University
                </a>
                , All Right Reserved. Designed By{" "}
                <a className="border-bottom" href="#">
                  Central Administration Division : Office of the President
                </a>
              </div>
              <div className="col-md-4 text-center text-md-end">
                <div className="footer-menu">
                  <a href="">Home</a>
                  {/* <a href="">Cookies</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default P6Footers;
