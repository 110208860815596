import React, { useState } from "react";
import { Col, Row } from "antd";
import { useCookies } from "react-cookie";
import "./index.css";

const POLICY_URL = "https://www.cmu.ac.th/th/privacy";

const P7Cookie = () => {
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <>
      {!cookies.cookieConsent && <CookieApporve />}
      <CookieDetails />
    </>
  );
};
export default P7Cookie;

/* ################################################################## */
/* ################################################################## */
/* #################                                ################# */
/* #################     START : Apporve Cookie     ################# */
/* #################                                ################# */
/* ################################################################## */
/* ################################################################## */

const CookieApporve = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
  return (
    <>
      <div className="cookie-message">
        <Col span={24}>
          <Row>
            <Col span={20}>
              <Row>
                <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                  <img src="https://img.icons8.com/plasticine/100/000000/cookie.png" />
                </Col>
                <Col span={20}>
                  <span>
                    เว็บไซต์นี้มีการเก็บคุกกี้ (Cookies)
                    เพื่อวิเคราะห์และปรับปรุงประสบการณ์ในการใช้งานเว็บไซต์ให้ดียิ่งขึ้น
                    ท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่
                    <a href={`${POLICY_URL}`}>นโยบายคุกกี้</a>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Col span={24}>
                <a className="approve" onClick={giveCookieConsent}>
                  <i className="fa fa-check"></i>
                </a>
              </Col>
              <Col>
                <a className="close">
                  <i className="fa fa-times"></i>
                </a>
              </Col>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

/* ################################################################## */
/* ################################################################## */
/* #################                                ################# */
/* #################      END : Apporve Cookie      ################# */
/* #################                                ################# */
/* ################################################################## */
/* ################################################################## */

/* ################################################################## */
/* ################################################################## */
/* #################                                ################# */
/* #################     START : Apporve Cookie     ################# */
/* #################                                ################# */
/* ################################################################## */
/* ################################################################## */

const CookieDetails = () => {
  const [frontCookie, setFrontCookie] = useState(true);
  const checkFrontCookie = (e) => {
    setFrontCookie(false);
  };

  const [openCookie, setOpenCookie] = useState(false);
  const checkOpenCookie = (e) => {
    setOpenCookie(false);
  };
  return (
    <>
      {frontCookie ? (
        <a
          className="cookie-button"
          onClick={(e) => {
            if (e === true) {
              checkFrontCookie();
            } else {
              setFrontCookie(false);
              setOpenCookie(true);
            }
          }}
        ></a>
      ) : null}

      {openCookie ? (
        <div className="cookie-message">
          <Col span={24}>
            <Row>
              <Col span={20}>
                <Row>
                  <Col
                    span={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src="https://img.icons8.com/plasticine/100/000000/cookie.png" />
                  </Col>
                  <Col span={20}>
                    <span>
                      เว็บไซต์นี้มีการเก็บคุกกี้ (Cookies)
                      เพื่อวิเคราะห์และปรับปรุงประสบการณ์ในการใช้งานเว็บไซต์ให้ดียิ่งขึ้น
                      ท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่
                      <a href={`${POLICY_URL}`}>นโยบายคุกกี้</a>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <Col span={24}>
                  <a
                    className="approve-true"
                    onClick={(e) => {
                      if (e === true) {
                        checkFrontCookie();
                      } else {
                        setFrontCookie(false);
                        setOpenCookie(true);
                      }
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </a>
                </Col>
                <Col>
                  <a
                    className="close"
                    onClick={(e) => {
                      if (e === true) {
                        checkOpenCookie();
                      } else {
                        setOpenCookie(false);
                        setFrontCookie(true);
                      }
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </a>
                </Col>
              </Col>
            </Row>
          </Col>
        </div>
      ) : null}
    </>
  );
};

/* ################################################################## */
/* ################################################################## */
/* #################                                ################# */
/* #################      END : Apporve Cookie      ################# */
/* #################                                ################# */
/* ################################################################## */
/* ################################################################## */
