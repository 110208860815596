const P1Header = () => {
  return (
    <div className="container-xxl py-2 bg-primary hero-header mb-5">
      <div className="container my-5 py-5 px-lg-5">
        <div className="row g-5 py-5">
          <div className="col-lg-6 text-center text-lg-start">
            <h1 className="text-white mb-5 animated zoomIn">
              กองบริหารงานกลาง <br />
              สำนักงานมหาวิทยาลัย
            </h1>
            <p className="text-white animated zoomIn">
              เป็นศูนย์กลางงานด้านสารบรรณ สนับสนุนงานผู้บริหาร
              <br />
              และงานประสานงานภายในมหาวิทยาลัย ให้ถูกต้อง รวดเร็ว
            </p>
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <img className="img-fluid" src="img/hero.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default P1Header;
