import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=VT323');

  :root {
    --light-grey: #ffffffcc;
    --title: #313335;
    --floor: #171f37;
    --base-font-size: 20px;
    --font-stack: 'VT323', monospace;
  }

  body, h1, h2, h3, h4, p, a {
    color: var(--light-grey) !important;
  }

  body, p {
    font: normal calc(var(--base-font-size) * 1px) / 1.25rem var(--font-stack) !important;
  }

  h1 {
    font: normal 2.75rem / 1.05em var(--font-stack) !important;
  }

  h2 {
    font: normal 2.25rem / 1.25em var(--font-stack) !important;
  }

  h3 {
    font: lighter 1.5rem / 1.25em var(--font-stack) !important;
  }

  h4 {
    font: lighter 1.125rem / 1.2222222em var(--font-stack) !important;
  }

  body {
    background: var(--floor) !important;
  }

  .arrow {
    color: #ffc720 !important;
    margin: 0 0 0 0.2rem !important;
    font-weight: bold !important;
    font-size: 1.4rem !important;
  }
`;

const Container = styled.div`
  width: 90%;
  margin: auto;
  max-width: 640px;
`;

const Bsod = styled.main`
  padding-top: 30%;
`;

const Neg = styled.h1`
  text-align: center;
  color: var(--floor);
`;

const Bg = styled.span`
  background: var(--title);
  border-radius: 15px;
  padding: 10px 30px 10px 30px;
`;

const Title = styled.div`
  margin-bottom: 50px;
`;

const Nav = styled.nav`
  margin-top: 35px;
  text-align: center;
`;

const Link = styled.a`
  text-decoration: none;
  padding: 0 9px 2px 8px;
  border-bottom: 1px solid #ffc720;

  &:hover,
  &:focus {
    background: var(--title);
    color: var(--floor);
    border-bottom: 1px solid #fff;
  }
`;

const Page404 = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Bsod>
          <Title>
            <Neg>
              <Bg>Error - 404</Bg>
            </Neg>
          </Title>
          <p>ขออภัยเราไม่พบ URL ที่คุณต้องการ เพื่อกำเนินการต่อโปรดเลือก :</p>
          <p>
            <span className="arrow">→</span> กลับไปที่หน้าหลักของเว็บไซต์
            &nbsp;|&nbsp; [ <b>webmaster</b> ]
            <br />
            <span className="arrow">→</span>{" "}
            ส่งข้อความถึงเราเกี่ยวกับข้อผิดพลาดนี้ &nbsp;|&nbsp; [{" "}
            <b>facebook</b> ]
          </p>
          <p>และลองใหม่อีกครั้งในภายหลัง</p>
          <Nav>
            <Link href="/">webmaster</Link>
            &nbsp;|&nbsp;
            <Link href="https://www.facebook.com/CMUgeneral" target="_blank">
              facebook
            </Link>
          </Nav>
        </Bsod>
      </Container>
    </>
  );
};

export default Page404;
