import "./index.css";

const POC1Header = () => {
  return (
    <>
      <div className="container-xxl py-5 bg-primary hero-header-orgchart mb-5">
        <div
          style={{
            height: "40px",
          }}
        ></div>
        <div className="container-xxl py-5 mb-5 org-images-title" />
      </div>

      <div className="container px-lg-5">
        <div className="row g-5 py-4">
          <div className="col-12 text-center org-bg-title">
            <h1 className="text-white animated zoomIn org-text-title">
              โครงสร้างหน่วยงาน
            </h1>
            <hr className="bg-white mx-auto mt-0" style={{ width: "50%" }} />
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center org-time-title">
                กองบริหารงานกลาง สำนักงานมหาวิทยาลัย มหาวิทยาลัยเชียงใหม่
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default POC1Header;
