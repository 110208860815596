import React, { useEffect, useState } from "react";
import { Modal, Carousel, Button, Row, Col, Typography } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import "./index.css";
import P0Navbar from "./P0Navbar";
import P1Header from "./P1Header";
import P2Offer from "./P2Offer";
import P3Publicrt from "./P3Publicrt";
import P4Service from "./P4Service";
import P5Personnel from "./P5Personnel";
import P6Footers from "./P6Footers";
import P7Cookie from "./P7Cookie";

const { Text } = Typography;

const Home = () => {
  // ###############################################################
  // ###############################################################
  // #######################                 #######################
  // #######################   Popup โฆษณา   #######################
  // #######################                 #######################
  // ###############################################################
  // ###############################################################

  // const ImageUrl = `${process.env.PUBLIC_URL}/Assets/popup`;
  // console.log(ImageUrl, "Path");
  // const [open, setOpen] = useState("true");
  // const [checkPopup, setCheckPopup] = useState();

  // const hideModal = () => {
  //   setOpen(false);
  // };

  // const turnOffModal = () => {
  //   setOpen(false);
  //   localStorage.setItem("POPUP_STATUS", "false");
  // };

  // useEffect(() => {
  //   const savedOpenState = localStorage.getItem("POPUP_STATUS");
  //   setCheckPopup(savedOpenState);
  //   if (savedOpenState === "false") {
  //     setOpen(false);
  //   }
  // }, [checkPopup]);

  // const handleCancel = () => {
  //   turnOffModal();
  // };

  // const SampleNextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         color: "black",
  //         paddingRight: "25px",
  //         fontSize: "25px",
  //         lineHeight: "1.5715",
  //       }}
  //       onClick={onClick}
  //     >
  //       <RightOutlined />
  //     </div>
  //   );
  // };

  // const SamplePrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         color: "black",
  //         padding: "0px 0px 0px 3px",
  //         fontSize: "25px",
  //         lineHeight: "1.5715",
  //       }}
  //       onClick={onClick}
  //     >
  //       <LeftOutlined />
  //     </div>
  //   );
  // };

  // const settings = {
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  // };

  // ###############################################################
  // ###############################################################
  // #######################                 #######################
  // #######################   Popup โฆษณา   #######################
  // #######################                 #######################
  // ###############################################################
  // ###############################################################

  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        {/* {checkPopup !== "false" ? (
          <Modal
            title={
              <Row gutter={5}>
                <Col>
                  <NotificationOutlined style={{ fontSize: "24px" }} />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2px",
                  }}
                >
                  <Text>
                    ป้ายประชาสัมพันธ์ กองบริหารงานกลาง มหาวิทยาลัยเชียงใหม่
                  </Text>
                </Col>
              </Row>
            }
            open={open}
            onOk={handleCancel}
            onCancel={hideModal}
            okText="ปิด"
            cancelText="ไม่แสดงอีก"
            width={"80%"}
            footer={[
              <Button
                style={{ background: "#fadb14", color: "#000" }}
                type="primary"
                onClick={handleCancel}
              >
                ไม่แสดงโฆษณาอีก
              </Button>,
              <Button type="primary" onClick={hideModal}>
                ปิดโฆษณา
              </Button>,
            ]}
          >
            <Carousel
              arrows
              {...settings}
              autoplay
              autoplaySpeed={5000}
              speed={1000}
            >
              <div>
                <img
                  className="modal-popup-image"
                  src={`${ImageUrl}/cadoou1.png`}
                />
              </div>
              <div>
                <a
                  href="https://www.facebook.com/cmuhrmd/posts/%EF%B8%8F-%F0%9D%97%96%F0%9D%97%A0%F0%9D%97%A8-%F0%9D%97%9B%F0%9D%97%A5-%E0%B8%82%E0%B8%AD%E0%B9%80%E0%B8%8A%E0%B8%B4%E0%B8%8D%E0%B8%8A%E0%B8%A7%E0%B8%99%E0%B8%9A%E0%B8%B8%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%81%E0%B8%A3%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A8%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%A3%E0%B9%88%E0%B8%A7%E0%B8%A1%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%A7%E0%B8%94%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%82%E0%B8%9A%E0%B8%A7%E0%B8%99%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A2%E0%B8%9E%E0%B8%B7%E0%B9%89%E0%B8%99%E0%B9%80%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%87%E0%B8%8A/922078279714466/"
                  target="_blank"
                >
                  <img
                    className="modal-popup-image"
                    src={`${ImageUrl}/cadoou2.jpg`}
                  />
                </a>
              </div>
            </Carousel>
          </Modal>
        ) : null} */}

        <P0Navbar />

        <P1Header />

        <P2Offer />

        <P3Publicrt />

        <P4Service Titled={"บริการ"} />

        <P5Personnel />

        <P6Footers />

        <P7Cookie />
      </div>
    </div>
  );
};
export default Home;
