import { Col, Divider, Row, Tooltip } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import PHLoading from "./PHLoading";

const apiURLNews = `${process.env.REACT_APP_API_URL}/noauth/clients/personnel/data`;

const P5Personnel = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [dataPersonnel, setDataPersonnel] = useState([]);

  const fetchDataPersonnel = async () => {
    setLoading(true);
    await axios.get(apiURLNews).then((res) => {
      setDataPersonnel(res.data);
    });
    setLoading(false);
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);

  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  useEffect(() => {
    fetchDataPersonnel();
  }, []);

  return (
    <div className="container-xxl py-5">
      <div className="container px-lg-5">
        <div
          className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <h2 className="mt-5">บุคลากร</h2>
        </div>
        {loading ? (
          <PHLoading />
        ) : (
          <>
            <div className="row">
              {dataPersonnel ? (
                /* eslint-disable-next-line */
                dataPersonnel.map((item, index) => {
                  return (
                    <Row>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {item.ranking === "ผู้อำนวยการ" ? (
                          <div
                            className="col-lg-4 col-md-12 wow fadeInUp"
                            data-wow-delay="0.1s"
                          >
                            <div className="team-item">
                              <div className="d-flex">
                                <div
                                  className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                  style={{ width: "75px" }}
                                >
                                  <Tooltip title="คัดลอกโทรศัพท์">
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.phones.substring(2)
                                        );
                                      }}
                                    >
                                      <i class="bi bi-telephone-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="left"
                                    title="คัดลอกอีเมล์"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.emails
                                        );
                                      }}
                                    >
                                      <i class="bi bi-envelope-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="bottom"
                                    title="เข้าสู่ Microsoft Teams"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      href="https://cmu.to/FLh2l"
                                      target="_blank"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-microsoft-teams"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                        <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                      </svg>
                                    </a>
                                  </Tooltip>
                                </div>
                                <div
                                  onMouseEnter={() => handleMouseEnter(item.id)}
                                  onMouseLeave={handleMouseLeave}
                                  className="img-personnel rounded"
                                  style={{
                                    width: "70%",
                                    height: "360px",
                                    overflow: "hidden",
                                    transition: "0.7s",
                                    position: "relative",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <img
                                    style={{ width: "100%" }}
                                    src={
                                      hoveredItemId === item.id
                                        ? item.imageIDPTwo
                                        : item.imageIDPOne
                                    }
                                    alt={`Person ${index + 1}`}
                                  />
                                </div>
                              </div>
                              <div className="px-4 py-3">
                                <h5 className="fw-bold m-0">
                                  {item.prefix + item.fname}&nbsp;{item.lname}
                                </h5>
                                <small>{item.ranking}</small>
                                <br />
                                <small>โทร : {item.phones.substring(2)}</small>
                                <br />
                                <small>E-Mail : {item.emails}</small>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <>ไม่พบข้อมูล</>
              )}
            </div>

            {/* <hr /> */}

            <div className="row g-4 mt-5" style={{ justifyContent: "center" }}>
              {dataPersonnel ? (
                /* eslint-disable-next-line */
                dataPersonnel.map((item, index) => {
                  if (item.status === 1) {
                    return (
                      <>
                        {item.ranking === "หัวหน้างานสารบรรณและธุรการ" ||
                        item.ranking === "หัวหน้างานประชุมและพิธีการ" ||
                        item.ranking ===
                          "หัวหน้างานศูนย์ประสานงานมหาวิทยาลัย (กทม.)" ? (
                          <div
                            className="col-lg-4 col-md-12 wow fadeInUp"
                            data-wow-delay="0.1s"
                          >
                            <div className="team-item">
                              <div className="d-flex">
                                <div
                                  className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                  style={{ width: "75px" }}
                                >
                                  <Tooltip title="คัดลอกโทรศัพท์">
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.phones.substring(2)
                                        );
                                      }}
                                    >
                                      <i class="bi bi-telephone-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="left"
                                    title="คัดลอกอีเมล์"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.emails
                                        );
                                      }}
                                    >
                                      <i class="bi bi-envelope-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="bottom"
                                    title="เข้าสู่ Microsoft Teams"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      href="https://cmu.to/FLh2l"
                                      target="_blank"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-microsoft-teams"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                        <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                      </svg>
                                    </a>
                                  </Tooltip>
                                </div>
                                {item.imageIDPOne === "" ||
                                item.imageIDPTwo === "" ? (
                                  <div
                                    className="img-personnel rounded"
                                    style={{
                                      width: "80%",
                                      height: "380px",
                                      overflow: "hidden",
                                      transition: "0.7s",
                                      position: "relative",
                                      borderRadius: "10px",
                                      display: "block",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      background:
                                        "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "100%",
                                      }}
                                      src={`${process.env.PUBLIC_URL}/Assets/img/logo-cropped.png`}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    onMouseEnter={() =>
                                      handleMouseEnter(item._id)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    className="img-personnel rounded"
                                    style={{
                                      width: "80%",
                                      height: "380px",
                                      overflow: "hidden",
                                      transition: "0.7s",
                                      position: "relative",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src={
                                        hoveredItemId === item._id
                                          ? item.imageIDPTwo
                                          : item.imageIDPOne
                                      }
                                      alt={`Person ${index + 1}`}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="px-4 py-3">
                                <h5 className="fw-bold m-0">
                                  {item.prefix + item.fname}&nbsp;{item.lname}
                                </h5>
                                <small>{item.ranking}</small>
                                <br />
                                <small>โทร : {item.phones.substring(2)}</small>
                                <br />
                                <small>E-Mail : {item.emails}</small>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  }
                })
              ) : (
                <>ไม่พบข้อมูล</>
              )}
            </div>
          </>
        )}
        <div
          class="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-lg-6 col-md-6 col-12 wow fadeInUp">
            <a
              href="/personnel"
              style={{ width: "100%", fontWeight: "bold" }}
              className="btn btn-personnel py-sm-3 px-sm-5 rounded-pill mt-5 animated fadeInUp"
            >
              บุคลากรทั้งหมด
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default P5Personnel;
