import { ConfigProvider, Tabs } from "antd";
import { useState } from "react";
import P0Navbar from "../Home/P0Navbar";
import P6Footers from "../Home/P6Footers";
import PSN1Header from "./PSN1Header";
import PSN2Divison from "./PSN2Divison";
import PSN3Saraban from "./PSN3Saraban";
import PSN4Meeting from "./PSN4Meeting";
import PSN5Coor from "./PSN5Coor";

const Personnels = ({}) => {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PSN1Header />
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                cardGutter: "50",
                itemSelectedColor: "#9171ee",
                itemHoverColor: "#fba504",
                cardBg: "null",
              },
            },
          }}
        >
          <Tabs
            type="card"
            size="large"
            animated={true}
            centered={true}
            tabPosition={"top"}
            defaultActiveKey={activeTab}
            onChange={onChange}
            items={[
              {
                key: "1",
                label: "ศูนย์บริหารกลาง (CSC)",
                children: <PSN2Divison />,
              },
              {
                key: "2",
                label: "สารบรรณและธุรการ",
                children: <PSN3Saraban />,
              },
              {
                key: "3",
                label: "ประชุมและพิธีการ",
                children: <PSN4Meeting />,
              },
              {
                key: "4",
                label: "ศูนย์ประสานงานมหาวิทยาลัย (กทม.)",
                children: <PSN5Coor />,
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <P6Footers />
    </div>
  );
};
export default Personnels;
