import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Col,
  Image,
  Row,
  Input,
  Spin,
  Space,
  List,
  Button,
  Typography,
} from "antd";
import {
  LoadingOutlined,
  DownloadOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  FilePptOutlined,
} from "@ant-design/icons";
import "./index.css";
import useQuery from "../../Library/useQuery";
import moment from "moment-timezone";
import "moment/min/locales";
import P0Navbar from "../Home/P0Navbar";
import P6Footers from "../Home/P6Footers";
import PNLoading from "./PNLoading";

const { TextArea } = Input;
const { Text } = Typography;

const API_URL =
  localStorage.getItem("API_URL") ||
  `${process.env.REACT_APP_API_URL}/noauth/clients/news`;

const NewsList = () => {
  moment.locale("th");
  const id = useQuery().get("id");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [dataNews, setDataNews] = useState([]);

  const getData = async () => {
    setLoading(true);
    await axios.get(`${API_URL}/data/${id}`).then((res) => {
      setDataNews(res.data);
    });
    setLoading(false);
  };

  const [img, setImg] = useState([]);
  // eslint-disable-next-line
  const [imageName, setImageName] = useState([]);
  // eslint-disable-next-line
  const [imageDownload, setImageDownload] = useState([]);

  // eslint-disable-next-line
  const [pdf, setPdf] = useState([]);
  // eslint-disable-next-line
  // eslint-disable-next-line
  const [pdfName, setPdfName] = useState([]);
  // eslint-disable-next-line
  const [pdfDownload, setPdfDownload] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    if (id) {
      axios.get(`${API_URL}/imgByNews?id=${id}`).then((res) => {
        let fetchApiName = [];
        let fetchName = [];
        let fetchApiDownload = [];
        for (let index = 0; index < res.data.length; index++) {
          let filename = res.data[index].filename;
          let id = res.data[index]._id;
          fetchApiName.push(API_URL + `/fileImage/${filename}?id=${id}`);
          fetchName.push(filename);
          fetchApiDownload.push(
            API_URL + `/fileImageDownload/${filename}?id=${id}`
          );
        }
        setImg(fetchApiName);
        setImageName(fetchName);
        setImageDownload(fetchApiDownload);
      });
    }
    if (id) {
      axios.get(`${API_URL}/pdfByNews?id=${id}`).then((res) => {
        let filesData = [];
        let filesProcessName = [];
        let filesApiDownload = [];
        for (let index = 0; index < res.data.length; index++) {
          let fileall = res.data[index];
          let filename = res.data[index].filename;
          let id = res.data[index]._id;
          filesData.push(fileall);
          filesProcessName.push(filename);
          filesApiDownload.push(
            API_URL + `/filePdfDownload/${filename}?id=${id}`
          );
        }
        setPdf(filesData);
        setPdfName(filesProcessName);
        setPdfDownload(filesApiDownload);
      });
    }
  }, [id]);

  const MockImage = ({ data, download, name }) => {
    return data ? (
      <Image
        className="uploadnew-mock-image"
        style={{ borderRadius: "10px", height: "150px", width: "150px" }}
        src={data}
        alt="404 image"
        preview={{
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
              },
            }
          ) => (
            <Space size={12} className="toolbar-wrapper">
              <DownloadOutlined onClick={() => onDownload(download, name)} />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      />
    ) : null;
  };

  const ImageAPI = ({ data, download, name }) => {
    const [imageData, setImageData] = useState(null);

    useEffect(() => {
      const fetchImageData = async () => {
        try {
          const response = await axios.get(data, {
            responseType: "arraybuffer",
          });
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setImageData(`data:;base64,${base64}`);
        } catch (error) {
          // console.error("Error fetching image data:", error);
        }
      };

      fetchImageData();
    }, [data]);

    return <MockImage data={imageData} download={download} name={name} />;
  };

  const onDownload = (download, name) => {
    fetch(download, {
      headers: new Headers({
        Authorization: "Bearer " + window.accessToken,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  return (
    <>
      <div className="container-xxl bg-white p-0">
        <div className="container-xxl position-relative p-0">
          <P0Navbar />

          <div className="container-xxl py-5 bg-primary hero-header-news mb-5">
            <div
              className="container-xxl py-5 mb-5 news-images-title"
              style={{ backgroundImage: `url(${dataNews.imageId})` }}
            />
          </div>
          <div className="container px-lg-5">
            <div className="row g-5 py-4">
              <div className="col-12 text-center news-bg-title">
                <h1 className="text-white animated zoomIn news-text-title">
                  {dataNews.newsTitle}
                </h1>
                <hr
                  className="bg-white mx-auto mt-0"
                  style={{ width: "50%" }}
                />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center news-time-title">
                    {moment(dataNews.timestart).format("LL")}
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          {loading ? (
            <PNLoading />
          ) : (
            <>
              <div className="container-xxl wow fadeInUp" data-wow-delay="0.1s">
                <div className="container px-lg-5 py-5">
                  <Row gutter={[0, 42]}>
                    <Col
                      span={24}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        style={{ width: "500px" }}
                        src={dataNews.imageId}
                      />
                    </Col>
                    <Col span={24}>
                      <Row gutter={[0, 32]}>
                        <Col span={24}>
                          <TextArea
                            readOnly
                            autoSize
                            bordered={false}
                            value={dataNews.details1}
                          />
                        </Col>
                        {dataNews.details2 === "" ? null : (
                          <Col span={24}>
                            <TextArea
                              readOnly
                              autoSize
                              bordered={false}
                              value={dataNews.details2}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>

                    <Col span={24}>
                      {img.length >= 1 ? (
                        <>
                          <h3>รูปภาพ</h3>
                          <Row gutter={[24, 24]}>
                            {img.map((item, index) => {
                              return (
                                <Col sx={12} lg={4}>
                                  <ImageAPI
                                    data={item}
                                    download={imageDownload[index]}
                                    name={imageName[index]}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      ) : null}
                    </Col>

                    <Col span={24}>
                      {pdf.length >= 1 ? (
                        <Row gutter={[0, 10]}>
                          <h3>ไฟล์เอกสาร</h3>
                          {pdf.map((item, index) => {
                            return (
                              <List
                                style={{ width: "100%" }}
                                bordered
                                dataSource={[item]}
                                renderItem={(item) => (
                                  <List.Item>
                                    <List.Item.Meta
                                      avatar={
                                        <FilePptOutlined
                                          style={{ fontSize: "42px" }}
                                        />
                                      }
                                      title={item.filename}
                                      description={
                                        <Col>{`${moment(item.uploadDate).format(
                                          "LL"
                                        )}`}</Col>
                                      }
                                    />
                                    <Col>
                                      <Button
                                        type="primary"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#13c2c2",
                                        }}
                                        onClick={() => {
                                          onDownload(
                                            pdfDownload[index],
                                            pdfName[index]
                                          );
                                        }}
                                      >
                                        ดาวน์โหลด
                                        {/* {t("ดาวน์โหลดไฟล์")} */}
                                      </Button>
                                    </Col>
                                  </List.Item>
                                )}
                              />
                            );
                          })}
                        </Row>
                      ) : null}
                    </Col>

                    {dataNews.footer === "" ? null : (
                      <>
                        <Col span={24}>
                          <Row>
                            <Col style={{ padding: "4.5px 0px 4.5px 0px" }}>
                              <Text>สอบถามเพิ่มเติม :</Text>
                            </Col>
                            {dataNews.footer === "กองบริหารงานกลาง" ? (
                              <Col
                                style={{
                                  marginLeft: "6px",
                                  padding: "4.5px 0px 4.5px 0px",
                                }}
                              >
                                <a
                                  href="https://www.facebook.com/CMUgeneral"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  กองบริหารงานกลาง สำนักงานมหาวิทยาลัย
                                  มหาวิทยาลัยเชียงใหม่
                                </a>
                              </Col>
                            ) : (
                              <Col>
                                <TextArea
                                  readOnly
                                  autoSize
                                  bordered={false}
                                  value={dataNews.footer}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <P6Footers className="container-xxl wow fadeInUp" data-wow-delay="5s" />
    </>
  );
};
export default NewsList;
