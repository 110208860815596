import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./utils";
import BlankPage from "../Components/BlankPage";
import Home from "../Pages/Home";
import Page404 from "../Pages/Page404";
import NewsList from "../Pages/News";
import Saraban from "../Pages/Saraban";
import Meeting from "../Pages/Meeting";
import Orgchart from "../Pages/Orgchart";
import Personnels from "../Pages/Personnel";
import PGReport from "../Pages/Meeting/PGReport";
import PGConfboard from "../Pages/Meeting/PGConfboard";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/newslist/id" component={NewsList} layout={BlankPage} />
        <PrivateRoute path="/personnel" component={Personnels} layout={BlankPage} />
        <PrivateRoute path="/orgchart" component={Orgchart} layout={BlankPage} />
        <PrivateRoute path="/meeting/board" component={PGConfboard} layout={BlankPage} />
        <PrivateRoute path="/meeting/report" component={PGReport} layout={BlankPage} />
        <PrivateRoute path="/meeting" component={Meeting} layout={BlankPage} />
        <PrivateRoute path="/saraban" component={Saraban} layout={BlankPage} />
        <PrivateRoute path="/home" component={Home} layout={BlankPage} />
        <PrivateRoute exact path="/" component={Home} layout={BlankPage} />
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};
export default Routes;
