const PSB1Header = () => {
  return (
    <div className="container-xxl py-5 bg-primary hero-header-saraban mb-5">
      <div className="container my-5 py-5 px-lg-5">
        <div className="row g-5 py-5">
          <div className="col-lg-6 text-center text-lg-start">
            <h1 className="text-white mb-4 animated zoomIn">
              งานสารบรรณและธุรการ
            </h1>
            <p className="text-white pb-3 animated zoomIn">
              รับผิดชอบในด้านการบริหารจัดการด้านเอกสารของมหาวิทยาลัย
              การโต้ตอบหนังสือของมหาวิทยาลัย
              โดยนำเสนอผู้บริหารระดับสูงของมหาวิทยาลัยพิจารณาสั่งการ
              การจัดเก็บเอกสาร การทำลายเอกสาร
              ซึ่งปัจจุบันได้นำระบบจัดเก็บเอกสารดิจิตอลมาใช้ในบางส่วน
              รับผิดชอบเกี่ยวกับงานพิธีการเฉพาะกิจต่างๆ ของมหาวิทยาลัย
              และงานพิธีการที่ร่วมกับส่วนราชการอื่น
            </p>
            {/* <a
              href=""
              className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"
            >
              Free Quote
            </a> */}
            {/* <a
              href=""
              className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
            >
              Contact Us
            </a> */}
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <img className="img-fluid" src="img/hero.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PSB1Header;
