import { Divider } from "antd";
import P0Navbar from "../Home/P0Navbar";
import POC1Header from "./POC1Header";
import POC2Divison from "./POC2Divison";
import POC3Saraban from "./POC3Saraban";
import POC4Meeting from "./POC4Meeting";
import POC5Coor from "./POC5Coor";
import P6Footers from "../Home/P6Footers";

const Title1 = "ศูนย์บริหารกลาง (CSC) ทั้งหมด";
const Title2 = "บุคลากรสารบรรณและธุรการทั้งหมด";
const Title3 = "บุคลากรประชุมและพิธีการทั้งหมด";
const Title4 = "บุคลากรศูนย์ประสานงานมหาวิทยาลัย (กทม.) ทั้งหมด";

const Orgchart = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <POC1Header />
        <POC2Divison btnTitle={Title1} />
        <Divider />
        <POC3Saraban btnTitle={Title2} />
        <Divider />
        <POC4Meeting btnTitle={Title3} />
        <Divider />
        <POC5Coor btnTitle={Title4} />
      </div>
        <P6Footers className="container-xxl wow fadeInUp" data-wow-delay="5s" />
    </div>
  );
};
export default Orgchart;
