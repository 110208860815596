import P0Navbar from "../Home/P0Navbar";
import PMT1Header from "./PMT1Header";
import PMT2Service from "./PMT2Service";
import P6Footers from "../Home/P6Footers";

const Meeting = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PMT1Header />
        <PMT2Service Titled={"ประชุมและพิธีการ"} />{" "}
        <P6Footers className="container-xxl wow fadeInUp" data-wow-delay="5s" />
      </div>
    </div>
  );
};

export default Meeting;
