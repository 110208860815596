import "./index.css";
import P0Navbar from "../../Home/P0Navbar";
import PGCB1Header from "./PGCB1Header";
import P6Footers from "../../Home/P6Footers";
import PGCB2Boardlist from "./PGCB2Boardlist";

const PGConfboard = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PGCB1Header />
        <PGCB2Boardlist />
      </div>
      <P6Footers />
    </div>
  );
};

export default PGConfboard;
