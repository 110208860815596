import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Col,
  Row,
  Spin,
  List,
  Button,
  message,
  Space,
  Modal,
  Tooltip,
} from "antd";
import { FilePptOutlined, CloseOutlined } from "@ant-design/icons";
import "./index.css";
import moment from "moment-timezone";
import "moment/min/locales";
import { Document, Page } from "react-pdf";
import PGRPLoading from "./PGRPLoading";

const API_URL =
  localStorage.getItem("API_URL") ||
  `${process.env.REACT_APP_API_URL}/noauth/clients/meeting`;

const PGRP2Replist = ({ SendYear, SendData, SendId }) => {
  moment.locale("th");

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [checkSendData, setCheckSendData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalYear, setModalYear] = useState("");

  const dataMeeting = () => {
    for (let index = 0; index < SendData.length; index++) {
      let year = SendData[index].repYear;
      setCheckSendData({ repYear: year });
    }
  };

  const [pdf, setPdf] = useState([]);
  const [pdfName, setPdfName] = useState([]);
  const [pdfDownload, setPdfDownload] = useState([]);

  const fetchMTFile = async (id) => {
    if (id) {
      try {
        const res = await axios.get(`${API_URL}/pdfRepo?id=${id}`);
        let filesData = [];
        let filesProcessName = [];
        let filesApiDownload = [];
        res.data.forEach((file) => {
          let filename = file.filename;
          let fileall = file;
          let fileId = file._id;
          filesData.push(fileall);
          filesProcessName.push(filename);
          filesApiDownload.push(
            `${API_URL}/file/download/${filename}?id=${fileId}`
          );
        });
        setPdf(filesData);
        setPdfName(filesProcessName);
        setPdfDownload(filesApiDownload);

        if (filesApiDownload.length > 0) {
          onDownload(filesApiDownload[0], filesProcessName[0]);
        } else {
          // console.log("No files to download");
          message.warning(
            <>
              <b>ไม่พบไฟล์เอกสาร!! </b>ที่ต้องการดาวน์โหลด <br />
              <span>กรุณาติดต่อแอดมิน</span>
            </>
          );
        }
      } catch (error) {
        // console.error("Error fetching file data:", error);
      }
    }
  };

  const onDownload = (download, name) => {
    fetch(download, {
      headers: new Headers({
        Authorization: "Bearer " + window.accessToken,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const [pdfReadData, setPdfReadData] = useState([]);
  const [pdfReadName, setPdfReadName] = useState([]);
  const [pdfDetail, setPdfDetail] = useState([]);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);

  const fetchMoveFile = async (id, title, year) => {
    if (id) {
      try {
        const res = await axios.get(`${API_URL}/pdfRepo?id=${id}`);
        let filesData = [];
        let filesProcessName = [];
        let filesApiDetail = [];
        res.data.forEach((file) => {
          let filename = file.filename;
          let fileall = file;
          let fileId = file._id;
          filesData.push(fileall);
          filesProcessName.push(filename);
          filesApiDetail.push(
            `${API_URL}/file/download/${filename}?id=${fileId}`
          );
        });
        setPdfReadData(filesData);
        setPdfReadName(filesProcessName);
        setPdfDetail(filesApiDetail);

        if (filesApiDetail.length > 0) {
          onReadPDF(filesApiDetail[0]);
          setModalTitle(title);
          setModalYear(year);
        } else {
          // console.log("No files to download");
          message.warning(
            <>
              <b>ไม่พบไฟล์เอกสาร!! </b>ที่ต้องการเปิด <br />
              <span>กรุณาติดต่อแอดมิน</span>
            </>
          );
        }
      } catch (error) {
        // console.error("Error fetching file data:", error);
      }
    }
  };

  const onReadPDF = (pdf) => {
    setOpen(true);
    setPdfFile(pdf);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    setLoading(true);
    dataMeeting();
    setLoading(false);
  }, [SendId]);

  return (
    <>
      <div className="container-xxl bg-white p-0">
        <div className="container-xxl position-relative p-0">
          {loading ? (
            <PGRPLoading />
          ) : (
            <div className="container-xxl wow fadeInUp" data-wow-delay="0.1s">
              <div className="container px-lg-5 py-5">
                <Row gutter={[0, 42]}>
                  <Col span={24}>
                    {SendData.length >= 1 ? (
                      <Row gutter={[0, 10]}>
                        <h3>
                          รายงานการประชุมคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่{" "}
                          {SendYear}
                        </h3>
                        {SendData.map((item, index) => {
                          return (
                            <>
                              <List
                                style={{ width: "100%" }}
                                bordered
                                dataSource={[item]}
                                renderItem={(item) => (
                                  <List.Item>
                                    <List.Item.Meta
                                      avatar={
                                        <FilePptOutlined
                                          style={{ fontSize: "42px" }}
                                        />
                                      }
                                      title={
                                        <>
                                          <span>{item.reTitle}</span>
                                          {" / "}
                                          <span>{item.repYear}</span>
                                        </>
                                      }
                                      description={
                                        <Col>{`${moment(item.timereport).format(
                                          "LL"
                                        )}`}</Col>
                                      }
                                    />
                                    <Space>
                                      <Col>
                                        <Button
                                          type="primary"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#9171ee",
                                          }}
                                          onClick={() => {
                                            fetchMoveFile(
                                              item._id,
                                              item.reTitle,
                                              item.repYear
                                            );
                                          }}
                                        >
                                          แสดงรายละเอียด
                                        </Button>
                                      </Col>
                                      <Col>
                                        <Button
                                          type="primary"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#13c2c2",
                                          }}
                                          onClick={() => {
                                            fetchMTFile(item._id);
                                          }}
                                        >
                                          ดาวน์โหลด
                                        </Button>
                                      </Col>
                                    </Space>
                                  </List.Item>
                                )}
                              />
                              <Modal
                                style={{
                                  top: "-80px",
                                  // display: "block",
                                  // justifyContent: "center",
                                }}
                                title={
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "15px 0px 15px 0px",
                                    }}
                                  >
                                    {modalTitle} {" / "} {modalYear}
                                  </h5>
                                }
                                centered
                                open={open}
                                closeIcon={
                                  <Tooltip title="ปิดหน้านี้">
                                    <CloseOutlined />
                                  </Tooltip>
                                }
                                onCancel={hideModal}
                                okButtonProps={{ style: { display: "none" } }}
                                cancelButtonProps={{
                                  style: {
                                    background: "#9171ee",
                                    color: "#fff",
                                  },
                                }}
                                cancelText="ปิดหน้านี้"
                                width={"auto"}
                                height={"80%"}
                              >
                                {pdfFile ? (
                                  <div className="pdf-div">
                                    <Document
                                      loading={<PGRPLoading />}
                                      file={pdfFile}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                      {Array.apply(null, Array(numPages))
                                        .map((x, i) => i + 1)
                                        .map((page) => {
                                          return (
                                            <>
                                              <p
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                หน้า {page} จาก {numPages}
                                              </p>
                                              <Page
                                                pageNumber={page}
                                                width={"800"}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                              />
                                            </>
                                          );
                                        })}
                                    </Document>
                                  </div>
                                ) : null}
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PGRP2Replist;
