const P2Offer = () => {
  return (
    <>
      <div className="container-xxl py-4">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mb-4 pb-2">
                <h2 className="mt-2">งานสารบรรณและธุรการ</h2>
              </div>
              <p className="mb-4">
                รับผิดชอบในด้านการบริหารจัดการด้านเอกสารของมหาวิทยาลัย
                <br />
                การโต้ตอบหนังสือของมหาวิทยาลัย
                โดยนำเสนอผู้บริหารระดับสูงของมหาวิทยาลัยพิจารณาสั่งการ
                <br />
                การจัดเก็บเอกสาร การทำลายเอกสาร
                ซึ่งปัจจุบันได้นำระบบจัดเก็บเอกสารดิจิตอลมาใช้ในบางส่วน
                <br />
                รับผิดชอบเกี่ยวกับงานพิธีการเฉพาะกิจต่าง ๆ ของมหาวิทยาลัย
                และงานพิธีการที่ร่วมกับส่วนราชการอื่น
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="Assets/img/P1.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-xxl bg-primary newsletter my-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container px-lg-5">
          <div className="row align-items-center" style={{ height: "250px" }}>
            <div className="col-md-6 text-center mb-n5 d-none d-md-block">
              <img
                className="img-fluid mt-5"
                style={{ height: 250 }}
                src={process.env.PUBLIC_URL + "/Assets/img/P2.png"}
              />
            </div>
            <div className="col-12 col-md-6">
              <h3 className="text-white">งานประชุมและพิธีการ</h3>
              <p className="text-white">
                เป็นฝ่ายเลขานุการที่ประชุมคณะกรรมการบริหารมหาวิทยาลัย
                <br />
                จัดเตรียมวาระและควบคุมการประชุมให้ดำเนินไปด้วยความเรียบร้อย
                <br />
                ดำเนินการประชุมระบบ e-meeting cmu
                <br />
                รับผิดชอบเกี่ยวกับงานพิธีการเฉพาะกิจต่าง ๆ ของมหาวิทยาลัย เช่น
                งานพิธีพระราชทานปริญญาบัตร
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-4">
        <div className="container px-lg-5 py-5 my-5">
          <div className="row g-5">
            <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mb-4 pb-2">
                <h2 className="mt-2">งานศูนย์ประสานงานมหาวิทยาลัย (กทม.)</h2>
              </div>
              <p className="mb-4">
                การดำเนินการอำนวยความสะดวกให้กับผู้บริหารระดับสูงของมหาวิทยาลัย
                ผู้บริหารส่วนงานและบุคลากรทั้งภายในและภายนอกมหาวิทยาลัย
                การดำเนินการเป็นผู้แทนมหาวิทยาลัย การติดต่อประสานงาน
                การจัดการประชุม การวางแผนงาน <br />{" "}
                การรับมอบหมายงานด้านต่างๆทั้งงานเร่งด่วน/งานเฉพาะกิจ
                การให้คำปรึกษาแนะนำและข้อชี้แจงต่างๆระหว่างมหาวิทยาลัยกับส่วนงานต่างๆของภาครัฐและเอกชน
                การรายงานผลการทำหนังสือเดินทาง (E-passport)
                รับหนังสือนำเพื่อขอรับการตรวจลงตราหนังสือเดินทางราชการ (Official
                Visa) และวีซ่า (Visa) ผ่านทางเว็บไซต์
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="/Assets/img/P3.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default P2Offer;
