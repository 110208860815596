import P0Navbar from "../Home/P0Navbar";
import P6Footers from "../Home/P6Footers";
import PSB1Header from "./PSB1Header";
import PSB2Detail from "./PSB2Detail";

const Saraban = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PSB1Header />
        <PSB2Detail Titled={"สารบรรณ"} />
        <P6Footers className="container-xxl wow fadeInUp" data-wow-delay="5s" />
      </div>
    </div>
  );
};

export default Saraban;
