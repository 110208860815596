import { Image, Tooltip } from "antd";
import axios from "axios";
import "./index.css";
import { useEffect, useState } from "react";
import PSNLoading from "../../Personnel/PSNLoading";

const apiURLNews = `${process.env.REACT_APP_API_URL}/noauth/clients/board/data`;

const PGCB2Boardlist = () => {
  const [loading, setloading] = useState(false);
  const [dataBoard, setDataBoard] = useState([]);

  const fetchDataBoard = async () => {
    setloading(true);
    await axios.get(apiURLNews).then((res) => {
      setDataBoard(res.data);
    });
    setloading(false);
  };

  useEffect(() => {
    fetchDataBoard();
  }, []);

  return (
    <div className="container-xxl py-5">
      <div className="container px-lg-5"></div>
      {loading ? (
        <PSNLoading />
      ) : (
        <>
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">คณะผู้บริหาร</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 0 ? (
                      <>
                        {item.posetions === "อธิการบดีมหาวิทยาลัยเชียงใหม่" ? (
                          <div
                            className="row pb-5"
                            style={{ justifyContent: "center" }}
                          >
                            <div
                              className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                              data-wow-delay="0.1s"
                            >
                              <div className="team-item">
                                <div className="d-flex">
                                  <div
                                    className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                    style={{ width: "75px" }}
                                  >
                                    <Tooltip title="คัดลอกโทรศัพท์">
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.phones.substring(2)
                                          );
                                        }}
                                      >
                                        <i className="bi bi-telephone-fill"></i>
                                      </a>
                                    </Tooltip>
                                    <Tooltip
                                      placement="left"
                                      title="คัดลอกอีเมล์"
                                    >
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.emails
                                          );
                                        }}
                                      >
                                        <i className="bi bi-envelope-fill"></i>
                                      </a>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      title="เข้าสู่ Microsoft Teams"
                                    >
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        href="https://cmu.to/FLh2l"
                                        target="_blank"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-microsoft-teams"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                          <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  </div>
                                  {item.images === "" ? (
                                    <div
                                      className="img-personnel rounded"
                                      style={{
                                        width: "80%",
                                        height: "380px",
                                        overflow: "hidden",
                                        transition: "0.7s",
                                        position: "relative",
                                        borderRadius: "10px",
                                        display: "block",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        background:
                                          "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                      }}
                                    >
                                      <Image
                                        preview={false}
                                        style={{
                                          width: "100%",
                                        }}
                                        src={`/Assets/img/logo-cropped.png`}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="img-personnel rounded"
                                      style={{
                                        width: "80%",
                                        height: "380px",
                                        overflow: "hidden",
                                        transition: "0.7s",
                                        position: "relative",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Image
                                        preview={false}
                                        style={{ width: "100%" }}
                                        src={item.images}
                                        alt={`Person ${index + 1}`}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="px-4 py-3">
                                  <h5 className="fw-bold m-0">
                                    {item.prefix + item.fname}&nbsp;{item.lname}
                                  </h5>
                                  <small>{item.posetions}</small>
                                  <br />
                                  <small>
                                    โทร : {item.phones.substring(2)}
                                  </small>
                                  <br />
                                  <small>E-Mail : {item.emails}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                            data-wow-delay="0.1s"
                          >
                            <div className="team-item">
                              <div className="d-flex">
                                <div
                                  className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                  style={{ width: "75px" }}
                                >
                                  <Tooltip title="คัดลอกโทรศัพท์">
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.phones.substring(2)
                                        );
                                      }}
                                    >
                                      <i className="bi bi-telephone-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="left"
                                    title="คัดลอกอีเมล์"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.emails
                                        );
                                      }}
                                    >
                                      <i className="bi bi-envelope-fill"></i>
                                    </a>
                                  </Tooltip>
                                  <Tooltip
                                    placement="bottom"
                                    title="เข้าสู่ Microsoft Teams"
                                  >
                                    <a
                                      className="btn btn-square text-primary bg-white my-1"
                                      href="https://cmu.to/FLh2l"
                                      target="_blank"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-microsoft-teams"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                        <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                      </svg>
                                    </a>
                                  </Tooltip>
                                </div>
                                {item.images === "" ? (
                                  <div
                                    className="img-personnel rounded"
                                    style={{
                                      width: "80%",
                                      height: "380px",
                                      overflow: "hidden",
                                      transition: "0.7s",
                                      position: "relative",
                                      borderRadius: "10px",
                                      display: "block",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      background:
                                        "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{
                                        width: "100%",
                                      }}
                                      src={`/Assets/img/logo-cropped.png`}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="img-personnel rounded"
                                    style={{
                                      width: "80%",
                                      height: "380px",
                                      overflow: "hidden",
                                      transition: "0.7s",
                                      position: "relative",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ width: "100%" }}
                                      src={item.images}
                                      alt={`Person ${index + 1}`}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="px-4 py-3">
                                <h5 className="fw-bold m-0">
                                  {item.prefix + item.fname}&nbsp;{item.lname}
                                </h5>
                                <small>{item.posetions}</small>
                                <br />
                                <small>โทร : {item.phones.substring(2)}</small>
                                <br />
                                <small>E-Mail : {item.emails}</small>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* ########################################################################################## */}
                      </>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">ผู้ช่วยอธิการบดี</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 8 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">กลุ่มสาขาวิชาวิทยาศาสตร์ และเทคโนโลยี</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 1 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">กลุ่มสาขาวิชาวิทยาศาสตร์สุขภาพ</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 2 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">กลุ่มสาขาวิชามนุษยศาสตร์ และสังคมศาสตร์</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 3 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">วิทยาลัย</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 4 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">สถาบัน</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 6 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">ส่วนงานอื่น</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 7 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}

          <div
            className="section-title position-relative text-left mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ marginLeft: "5%" }}
          >
            <h5 className="mt-5">ส่วนงานวิชาการภายใน</h5>
          </div>

          <div className="row pb-5" style={{ justifyContent: "center" }}>
            {dataBoard ? (
              /* eslint-disable-next-line */
              dataBoard.map((item, index) => {
                return (
                  <>
                    {item.branchgroup === 9 ? (
                      <div
                        className="col-lg-4 col-md-12 py-5 pb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="team-item">
                          <div className="d-flex">
                            <div
                              className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                              style={{ width: "75px" }}
                            >
                              <Tooltip title="คัดลอกโทรศัพท์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.phones.substring(2)
                                    );
                                  }}
                                >
                                  <i className="bi bi-telephone-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip placement="left" title="คัดลอกอีเมล์">
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.emails);
                                  }}
                                >
                                  <i className="bi bi-envelope-fill"></i>
                                </a>
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="เข้าสู่ Microsoft Teams"
                              >
                                <a
                                  className="btn btn-square text-primary bg-white my-1"
                                  href="https://cmu.to/FLh2l"
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-microsoft-teams"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                  </svg>
                                </a>
                              </Tooltip>
                            </div>
                            {item.images === "" ? (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                  display: "block",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  background:
                                    "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{
                                    width: "100%",
                                  }}
                                  src={`/Assets/img/logo-cropped.png`}
                                />
                              </div>
                            ) : (
                              <div
                                className="img-personnel rounded"
                                style={{
                                  width: "80%",
                                  height: "380px",
                                  overflow: "hidden",
                                  transition: "0.7s",
                                  position: "relative",
                                  borderRadius: "10px",
                                }}
                              >
                                <Image
                                  preview={false}
                                  style={{ width: "100%" }}
                                  src={item.images}
                                  alt={`Person ${index + 1}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <h5 className="fw-bold m-0">
                              {item.prefix + item.fname}&nbsp;{item.lname}
                            </h5>
                            <small>{item.posetions}</small>
                            <br />
                            <small>โทร : {item.phones.substring(2)}</small>
                            <br />
                            <small>E-Mail : {item.emails}</small>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <>ไม่พบข้อมูล</>
            )}
          </div>

          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
          {/* ########################################################################################## */}
        </>
      )}
    </div>
  );
};
export default PGCB2Boardlist;
