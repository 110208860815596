import "./index.css";

const PNLoading = () => {
  return (
    <>
      {/* Start : CSS Loading One */}
      {/* <center>
<div class="loader" id="loader"></div>
<div class="loader" id="loader2"></div>
<div class="loader" id="loader3"></div>
<div class="loader" id="loader4"></div>
<span id="text">LOADING...</span>
</center> */}
      {/* END : CSS Loading One */}

      <div id="box-container">
        <div className="boxes box1"></div>
        <div className="boxes box2"></div>
        <div className="boxes box3"></div>
      </div>
      <div className="text">
        <div className="loading">
          <b>Loading</b>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default PNLoading;
